import { useRef, useState, useEffect, useCallback, ReactNode } from 'react';
import FormGroupWrap from '@/ts-common/components/form/helpers/FormGroupWrap';
import useOnClickOutside from 'common/utils/hooks/useOnClickOutside';
import _debounce from 'lodash/debounce';
import { getAsyncOptions } from 'utils/helpers';
import { FC } from 'react';
import { getTagsSettings } from '@/api/tags/api.ts';
import { errorHandler } from '@/common/utils/notifications';
import { useAppDispatch } from '@/store/hooks';
import { useGetTagSettings } from '@/api/tags/queries';
import { css } from '@emotion/react';
import { TagType } from '@/common/types/enums.ts';
import Select from '@/ts-common/components/form/inputs/select';

interface TagProps {
  tags?: string[];
  onChange: (tags: string[]) => void;
  label?: string | ReactNode;
  className?: string;
  disabled?: boolean;
  type: TagType;
  suggestionsDisabled?: boolean;
  invisible?: boolean;
  placeholder?: string;
}

type SuggestionType = {
  name: string;
};

const Tag: FC<TagProps> = ({
  tags = [],
  onChange,
  label = 'Tags',
  className = '',
  disabled = false,
  type,
  suggestionsDisabled = false,
  invisible = false,
  placeholder = 'Add a tag'
}) => {
  const [suggestions, setSuggestions] = useState<SuggestionType[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [input, setInput] = useState<string>('');
  const tagRef = useRef<HTMLInputElement>(null);
  const layoutRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const getSettings = useCallback(async () => {
    try {
      await getTagsSettings();
    } catch (error) {
      if (error instanceof Error) {
        dispatch(errorHandler({ title: 'Error!', message: error.message }));
      }
    }
  }, [dispatch]);

  const { data: tagSettings } = useGetTagSettings();

  const canCreateTag = tagSettings ? tagSettings[type] : false;

  const loadSuggestions = async (search: string) => {
    const res = await getAsyncOptions(search, 'tags', {
      type: type,
      exclude_tags: tags
    });

    if (res.length) {
      setSuggestions(res.map(({ name }: { name: string }) => ({ name })));
    }
  };

  const debouncedLoadSuggestions = _debounce(loadSuggestions, 500);

  const handleClickInside = () => {
    if (!isFocused) {
      setIsFocused(true);
    }
  };

  const closeOnClickOutside = () => {
    if (tagRef.current) {
      tagRef.current.blur();
      tagRef.current.value = '';
      setIsFocused(false);
    }
  };

  useOnClickOutside(layoutRef, closeOnClickOutside, handleClickInside);

  const addTag = (tag: string) => {
    if (!tags.includes(tag)) {
      onChange([...tags, tag]);
      setInput('');
      setSuggestions([]);
    }
  };

  const removeTag = (index: number) => {
    onChange(tags.filter((_, i) => i !== index));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInput(value);
    if (!suggestionsDisabled) debouncedLoadSuggestions(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && input.trim()) {
      e.preventDefault();
      addTag(input.trim());
    }
  };

  useEffect(() => {
    if (input && suggestions?.filter(suggestion => suggestion?.name.startsWith(input))) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [input, isFocused, suggestions]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <FormGroupWrap
      className={`tags-group ${disabled ? 'disabled' : ''} ${invisible ? 'invisible-input' : ''} ${className}`}
      label={label}
    >
      <div
        ref={layoutRef}
        onClick={() => (tagRef.current && !disabled ? tagRef.current.focus() : '')}
      >
        <div className={`tags-input ${isFocused ? 'tags-input--focused' : ''}`}>
          <div className={`tags-input--wrapper`}>
            {tags.map((tag, index) => (
              <span key={index} className="tags-input-tag">
                {tag}
                {!disabled ? (
                  <span className="cursor-pointer cps-4 cpe-2" onClick={() => removeTag(index)}>
                    ×
                  </span>
                ) : null}
              </span>
            ))}
            {canCreateTag ? (
              <>
                {(tags.length > 0 && !isFocused) || isFocused ? (
                  <input
                    type="text"
                    autoComplete="off"
                    aria-autocomplete="list"
                    className="tags-text-input"
                    placeholder={placeholder}
                    ref={tagRef}
                    value={input}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    disabled={disabled}
                    css={css`
                      flex: 1;
                      min-width: 0 !important;
                    `}
                  />
                ) : null}
                {suggestions.length && showSuggestions ? (
                  <div role="listbox" className={`suggestions-container `}>
                    <ul className="suggestions">
                      {suggestions.map((suggestion, i) => (
                        <li
                          key={i}
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            addTag(suggestion.name);
                          }}
                        >
                          {suggestion.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </>
            ) : (
              <Select
                isAsync={false}
                getOptionValue={(option: SuggestionType) => option.name.toString()}
                getOptionLabel={(option: SuggestionType) => option.name.toString()}
                onChange={e => {
                  if (e?.name) {
                    addTag(e.name);
                  }
                }}
                memoizedRequestParams={{
                  path: 'lists',
                  params: {
                    list: 'tags',
                    type: type,
                    exclude_tags: tags
                  }
                }}
                className="flex-1 px-1 min-w-120"
                placeholder="Select Tag"
                invisible
              />
            )}
          </div>
        </div>
      </div>
    </FormGroupWrap>
  );
};

export default Tag;
