import { put, post, deleteRequest, get } from '@/api';
import { TagSettingsType, TagType } from '@/common/types/tags';
import { RequestParamsType, TableQueryPaginatedResponse } from '@webthatmatters/orca-table';

type CreateTagParams = {
  name: string;
  type: string;
};

type UpdateTagParams = {
  name: string;
  type: string;
  id: number;
};

type MergeTagsParams = {
  tag_ids: number[];
  name: string;
};

type UpdateTagsSettingsParams = {
  [key: string]: boolean;
};

export const getTags = async (params: RequestParamsType) => {
  const response = await get<TableQueryPaginatedResponse<TagType>>(`/tags`, params);

  return response.data;
};

export const createTag = async (params: CreateTagParams) => {
  const response = await post<TagType[]>('/tags', params);

  return response.data;
};

export const updateTag = async (params: UpdateTagParams) => {
  const { id, ...rest } = params;
  const response = await put<TagType[]>(`/tags/${id}`, rest);

  return response.data;
};

export const deleteTag = async (params: { id: number }) => {
  const response = await deleteRequest<TagType[]>(`/tags/${params.id}`);

  return response.data;
};

export const mergeTags = async (params: MergeTagsParams) => {
  const response = await post<TagType[]>(`/tags/merge`, params);

  return response.data;
};

export const getTagsSettings = async () => {
  const response = await get<TagSettingsType>(`/tags/settings`);

  return response.data;
};

export const updateTagsSettings = async (params: UpdateTagsSettingsParams) => {
  const response = await put(`/tags/settings`, params);

  return response.data;
};
